import React, { Suspense, useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import useToken from "./Hooks/useToken";

// pages componnet

import Login from "./components/Auth/Login";
import ForgetPass from "./components/Auth/ForgetPass";
import SignUp from "./components/Auth/SignUp";
import AuthLayout from "./layouts/AuthLayout";

import PrivateRoutes from "./utils/PrivateRouts";
import AuthRoutes from "./utils/AuthRouts";
// import ResultPage from "./pages/ResultPage/ResultPage";

import PlanListSkeleton from "./components/skeleton/PlanListSkeleton";
import PlanSkeleton from "./components/skeleton/PlanSkeleton";
import ProfilePopup from "./components/profile/ProfilePopup";

// import Error from './pages/ErrorPage/Error';
import Error from "./components/Error";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Maintenance from "./pages/MaintenancePage/Maintenance";

import ReelReport from "./pages/SampleReportPage/ReelReport";
import NetworkErrorPage from "./pages/NetworkErrorPage/NetworkErrorPage";
import Campaign from './pages/campaignComponent/Campaign';
import ValueMatch from './pages/ValueMatch';
import EngRateCal from './pages/EngRateCal';
import SimilarProfile from './pages/similarProfile/SimilarProfile';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import HashtagReport from './pages/hashtagReport/HashtagReport';
import HashtagSingleReport from './pages/hashtagReport/HashtagSingleReport';
import WalletHistory from './pages/WalletHistory';
import axiosInstance from './interseptor';
import ViralBrandReport from './pages/viralbrandreport/ViralBrandReport';
import ViralBrandSingleReport from './pages/viralbrandreport/ViralBrandSingleReport';
import ViralReportUpdateSheet from './pages/ViralReportUpdate/ViralReportUpdateSheet';
import HashtagReportCopy from './pages/hashtagReportCopy/HashtagReportCopy';
import HashtagSingleReportCopy from './pages/hashtagReportCopy/HashtagSingleReportCopy';
import CategoryPage from './pages/CategoryPage/CategoryPage';
import axios from 'axios';
import { PersistGate } from 'redux-persist/integration/react';
import { decryptData } from './components/Auth/Encryption';
import HomePage from './pages/HomePage/HomePage';
import ResultPage from './pages/ResultPage/ResultPage';
import CampaignList from './pages/campaignList/CampaignList';
// import StoryReport from "./pages/SampleReportPage/StoryReport";
const Dashboard = React.lazy(() => import("./layouts/DashboardLayout"));
const ContentInspiration = React.lazy(() => import("./pages/contentInspiration/ContentInspiration"));
// const Campaign = React.lazy(() => import("./pages/campaign/Campaign"));

const SingleReport = React.lazy(() => import("./components/Report/singleReport"));
// const HomePage = React.lazy(() => import('./pages/HomePage/HomePage'));
// const ResultPage = React.lazy(() => import('./pages/ResultPage/ResultPage'))

const SearchPage = React.lazy(() => import("./pages/SearchPage/SearchPage"))
const PlanPage = React.lazy(() => import("./pages/PlanPage/PlanPage"));
const PlansList = React.lazy(() => import("./components/PlanComponent/planlist/PlansList"));
const ReportPage = React.lazy(() => import('./pages/ReportPage/ReportPage'))
const ProfilePage = React.lazy(() => import('./pages/ProfilePage/ProfilePage'))
const StoryReport = React.lazy(() => import('./pages/SampleReportPage/StoryReport'));
// const GET_CREDIT_HISTORY = "/livesearch/credit_history";

const CHECK_VALIDATE_URL = 'livesearch/checkvalidate';

function App() {
  const { token, setToken } = useToken();
  const [shared, setShared] = useState(false);
  const navigate = useNavigate()
  const location = useLocation()
  // const { gettoken, getToken } = useToken();

  // const getCreditHistory = async () => {

  //   let data = new FormData();
  //   data.append("logintype", "credit_history");
  //   data.append("device_id", "seacrh");

  //   try {
  //     const response = await axiosInstance.post(GET_CREDIT_HISTORY, data);

  //     console.log('Credit History Status', response?.data?.status)
  //     localStorage.setitem('credit',response?.data?.status)
  //     if (response?.data?.status === 0) {
  //       alert(response?.data?.msg)
  //     }

  //     if (response?.data?.status_code == 200) {


  //       if (response?.data?.status == 1) {
  //       } else {

  //       }
  //     } else if (response && response?.data && response?.data?.status == 0) {
  //       console.log({ message: response?.data?.msg, code: 401 });
  //       return false;
  //     } else if (response && response?.data && response?.data?.status == 3) {
  //       console.log({ message: response?.data?.msg, code: 401 });
  //       return false;
  //     }

  //     return response?.data.list;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // useEffect(() => {
  //   getCreditHistory()
  // }, [location])

  // Function to check if a cookie has expired
  function getCookie(name) {
    const cookieArr = document.cookie.split(';');
    for (let i = 0; i < cookieArr.length; i++) {
      const cookiePair = cookieArr[i].split('=');
      if (name === cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }

  function isCookieExpired(cookieName) {
    const cookieValue = getCookie(cookieName);
    if (cookieValue) {
      const expirationDate = new Date(cookieValue);
      console.log(cookieValue, expirationDate, new Date(), expirationDate < new Date())

      return expirationDate < new Date();
    }
    return true; // Cookie doesn't exist or malformed
  }
  useEffect(() => {

    const regex = /^[a-zA-Z0-9]{27}$/;
    const regexCheck = async () => {
      if (regex.test(location.pathname.split('/')[1])) {
        localStorage.setItem("shared", "shared")
        const formdata = new FormData();
        formdata.append("logintype", "checkvalidate");
        formdata.append("device_id", "search");
        formdata.append("link", location.pathname.split('/')[1]);
        // formdata.append("link", location.pathname.split('/')[1]);
        console.log("data set")
        const respinse = await axiosInstance.post(CHECK_VALIDATE_URL, formdata)
        if (respinse.data && respinse.data.data && typeof respinse.data.data === 'object') {
          setShared(true)
          if (!localStorage.getItem('token_data')) {
            localStorage.setItem("token_data", "SHARED")
          }

          console.log(JSON.parse(respinse.data.data.viewplan))
          console.log(JSON.parse(respinse.data.data.viewlist))

          //  <PlanDList viewplan={JSON.parse(respinse.data.data.viewplan)} viewlist={JSON.parse(respinse.data.data.viewlist)} />
          localStorage.setItem("shared", "shared")
          return navigate(`/plans/list/?pid=${respinse.data.data.plan_id}`, {
            state: {
              viewplan: JSON.parse(respinse.data.data.viewplan),
              viewlist: JSON.parse(respinse.data.data.viewlist),
              link: location.pathname.split('/')[1]
            }
          });
        }
        console.log("String matches the pattern.");
      } else {

        console.log("String does not match the pattern.");
      }

    }
    regexCheck()

  }, [])
  
  useEffect(() => {
    const regex = /^[a-zA-Z0-9]{27}$/;
    if (!regex.test(location.pathname.split('/')[1])) {
      console.log("im in ")
      if ((!location.pathname.includes('login') && location.pathname !== '/') && !location.pathname.includes('plans')) {
        if (isCookieExpired("emails")) {
          console.log("The 'emails' cookie has expired.");
          // Handle expired cookie here, such as clearing it
          document.cookie = "emails=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          localStorage.clear();
          window.location.href = '/login'
        }
      }
    }
  }, [location.pathname])


  if (!localStorage.getItem("rights_navigation")) {
    localStorage.setItem("rights_navigation", "")
    localStorage.setItem("rights_action", "")
  }

  if (!localStorage.getItem("email")) {
    localStorage.setItem("email", "")

  }


  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Error>
            <Suspense fallback={<div className="w-full h-screen flex justify-center items-center"><div className="text-4xl font-semibold text-[#6d5eac] animate-pulse">Loading...</div></div>}>
              <Routes>
                <Route element={<AuthLayout setToken={setToken} />}>
                  <Route
                    index
                    path="/"
                    element={<Login setToken={setToken} />}
                    exact
                  ></Route>
                  <Route
                    index
                    path="/login"
                    element={<Login setToken={setToken} />}
                    exact
                  ></Route>
                  <Route path="/signup" element={<SignUp />} exact></Route>
                  <Route path="/forget" element={<ForgetPass />} exact></Route>
                </Route>

                <Route element={<PrivateRoutes />}>
                  <Route element={<Dashboard />}>
                    {decryptData(localStorage.getItem("rights_navigation"))?.includes('73') && <Route index path="/home/" element={<HomePage />}></Route>}
                    {decryptData(localStorage.getItem("rights_navigation"))?.includes('73') && <Route index path="/search/" element={<SearchPage />}></Route>}

                    {/* <Route index path="/result/" element={<ResultPage/>} ></Route> */}
                    {decryptData(localStorage.getItem("rights_navigation"))?.includes('73') && <Route index path="/result/" element={<ResultPage />}></Route>}
                    {decryptData(localStorage.getItem("rights_navigation"))?.includes('74') && <Route index path="/plans/" element={<PlanPage />}></Route>}
                    {(decryptData(localStorage.getItem("rights_navigation"))?.includes('74') || localStorage.getItem("shared") == "shared" || localStorage.getItem("shared") == "true") && <Route index path="/plans/list/" element={<PlansList />}></Route>}
                    {decryptData(localStorage.getItem("rights_navigation"))?.includes('75') && <Route index path="/campaign_list/" element={<CampaignList />}></Route>}
                    {decryptData(localStorage.getItem("rights_navigation"))?.includes('76') && <Route index path="/report/" element={<ReportPage />}></Route>}
                    {decryptData(localStorage.getItem("rights_navigation"))?.includes('76') && <Route index path="/report/:id" element={<SingleReport />}></Route>}
                    {decryptData(localStorage.getItem("rights_navigation"))?.includes('77') && <Route index path="/HashtagReport" element={<HashtagReport />} ></Route>}
                    {decryptData(localStorage.getItem("rights_navigation"))?.includes('77') && <Route index path="/HashtagReportCopy" element={<HashtagReportCopy />} ></Route>}
                    {decryptData(localStorage.getItem("rights_navigation"))?.includes('77') && <Route index path="/hashtagreport/:id" element={<HashtagSingleReport />}></Route>}
                    {decryptData(localStorage.getItem("rights_navigation"))?.includes('77') && <Route index path="/hashtagreportCopy/:id" element={<HashtagSingleReportCopy />}></Route>}
                    {decryptData(localStorage.getItem("rights_navigation"))?.includes('78') && <Route index path="/ViralBrandReport" element={<ViralBrandReport />} ></Route>}
                    {decryptData(localStorage.getItem("rights_navigation"))?.includes('78') && <Route index path="/ViralBrandReport/:id" element={<ViralBrandSingleReport />}></Route>}
                    {decryptData(localStorage.getItem("rights_navigation"))?.includes('79') && <Route index path="/ViralReportUpdate" element={<ViralReportUpdateSheet />} ></Route>}
                    {decryptData(localStorage.getItem("rights_navigation"))?.includes('82') && <Route index path="/update-creator" element={<CategoryPage />} ></Route>}
                    <Route index path="/profile/" element={<ProfilePage />}></Route>
                    <Route index path="/skeleton/" element={<PlanSkeleton />}></Route>
                    <Route
                      index
                      path="/profilepopup/"
                      element={<ProfilePopup />}
                    ></Route>
                    <Route
                      index
                      path="/pagenotfound/"
                      element={<PageNotFound />}
                    ></Route>
                    <Route
                      index
                      path="/maintenance/"
                      element={<Maintenance />}
                    ></Route>
                    <Route
                      index
                      path="/contentinspiration"
                      element={<ContentInspiration />}
                    ></Route>

                    {decryptData(localStorage.getItem("rights_navigation"))?.includes('80') && <Route
                      index
                      path="/value-match"
                      element={<ValueMatch />}
                    ></Route>}
                    {decryptData(localStorage.getItem("rights_navigation"))?.includes('81') && <Route
                      index
                      path="/engagement-rate-calculator"
                      element={<EngRateCal />}
                    ></Route>
                    }
                    {decryptData(localStorage.getItem("rights_navigation"))?.includes('82') && <Route
                      index
                      path="/similar"
                      element={<SimilarProfile />}
                    ></Route>}
                    <Route
                      index
                      path="/wallet-history"
                      element={<WalletHistory />}
                    ></Route>
                    <Route
                      index
                      path="/campaign"
                      element={<Campaign />}
                    ></Route>
                    <Route index path="/reelreport" element={<ReelReport />}></Route>
                    <Route
                      index
                      path="/storyreport"
                      element={<StoryReport />}
                    ></Route>

                    <Route index path="error" element={<NetworkErrorPage />}></Route>
                    <Route index path="*" element={<PageNotFound />}></Route>
                  </Route>
                </Route>
              </Routes>
            </Suspense>
          </Error>
        </PersistGate>
      </Provider>
    </>
  );
}
export default App;
